import Footer from 'components/modules/landing/alternate/Footer';
import HeroHeader from 'components/modules/landing/alternate/HeroHeader';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

const Alternate = () => {
  useSettingsMountEffect({
    disableNavigationType: true,
    disableHorizontalNavbarAppearance: true,
    disableVerticalNavbarAppearance: true,
    disableHorizontalNavbarShape: true
  });
  return (
    <div className="bg-body-emphasis dark__bg-gray-1200">
      <HeroHeader />
      <Footer />
    </div>
  );
};

export default Alternate;
