const Footer = () => {
  return (
    <section data-bs-theme="dark" className="bg-dark dark__bg-gray-1000">
      <div className="container-small px-lg-7 px-xxl-3">
        <hr className="border-top" />
        <div className="d-sm-flex flex-between-center text-center">
          <p className="text-body-tertiary mb-0">Copyright © FuturMill</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
