import { RouteObject, createBrowserRouter } from 'react-router-dom';
import Error404 from 'pages/error/Error404';
import App from 'App';
import Alternate from 'pages/pages/landing/Alternate';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Alternate />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
